.row {
  width: 100%;
  border: 1px solid #1c1c00;
  font-family: "FixedsysExcelsior301Regular";
  padding: 5px 5px 5px 5px;
  display: flex;
  flex-direction: row;
}

.row:hover {
  cursor: pointer;
}

.date {
  margin-left: 7px;
  color: red;
  min-width: 75px;
}

.name {
  margin-left: 7px;
  color: white;
  width: calc(100% - 125px);
  overflow-x: hidden;
}

.clear {
  clear: both;
}
