.helpcontainer {
  height: 100%;
  width: 8vh;
  min-width: 60px;
  background-color: #black;
}

.help {
  height: 100%;
  width: 100%;
  padding: 5px;
  background-color: #black;
  cursor: help;
  border: 3px;
  border-style: outset;
  border-color: #ececec #c0c0c0;
}
