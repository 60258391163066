.backtolive {
  font-size: 2vh;
  line-height: 4vh;
  font-family:'Arial Narrow', Arial, sans-serif;
}

@media (max-width: 1500px) {
  .backtolive {
    font-size: 15px;
    line-height: 30px;
  }
}