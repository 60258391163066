@import './Select.css';

.background {
  background: blue;
  width: 100%;
  min-height: 100%;
}

.header {
  margin: 0 auto;
  width: 50%;
  font-size: 1.1vw;
  color: white;
}

.subheader {
  margin: 0.5em auto;
  width: 50%;
  font-size: 1em;
  color: white;
  font-family: Courier New, Courier, monospace;
}

.logoutbutton {
  margin: 0.35em;
}

.admin {
  display: block;
  width: 70%;
  max-width: 900px;
  min-width: 700px;
  margin: auto;
  font-family: Courier New, Courier, monospace;
}

.admin select {
  font-family: Courier New, Courier, monospace;
}

.admin button {
  font-family: Courier New, Courier, monospace;
}

.contenttypecontainer {
  background: lightgray;
  border-top: 2px dashed black;
  padding: 15px;
}

.contenttype {
  font-size: 2em;
}

@media only screen 
  and (max-device-width: 750px) {
    .admin {
      width: 100vw;
      max-width: 100vw;
      min-width: 100vw;
    }
    .admin select {
      display: block;
      max-width: 93vw;
    }
}