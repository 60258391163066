.nextplaying {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 2.5px;
  color: white;
  font-family: "FixedsysExcelsior301Regular";
  font-size: 3vh;
  line-height: 4vh;
  overflow: hidden;
}

.tickertext {
  color: white;
}

@media only screen 
  and (max-device-width: 1124px) {
    .nextplaying {
      min-width: 150px;
    }
}

@media only screen 
  and (max-height: 600px) {
    .nextplaying {
      font-size: 20px;
      line-height: 25px;
    }
}