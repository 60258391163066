.topbar {
  height: 9vh;
  min-height: 60px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  z-index: 999;
  border: 4px ridge;
  box-sizing: content-box;
  border-color: #ececec #d3d3d3;
}

.topleft {
  height: 100%;
  min-width: 230px;
  flex-grow: 1;
  width: calc(50.3vw - 360px);
  background: black;
  color: white;
  display: flex;
  flex-direction: row;
}

#player {
  display: inherit;
  width: 80%;
  background: yellow;
}

#egg {
  width: 400px;
  display: flex;
  background: blue;
}

.desktop {
  display: flex;
  flex-direction: row;
  height: 100%;
}

@media screen and (min-width: 1400px) {
  .container {
    width: 1370px;
  }
}

@media only screen 
  and (max-device-width: 750px)
  and (orientation: landscape) {
    .topbar {
      position: fixed;
      top: 100%;
      left: 0px;
      width: calc(100vh);
      transform: rotate(270deg);
      transform-origin: 0% 0%;
    }
}

@media only screen 
  and (max-device-width: 750px)
  and (orientation: portrait) {
    .topbar {
      height: 60px;
    }
}