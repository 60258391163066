.backgroundpicker {
  width: 18vh;
  min-width: 120px;
  height: 100%;
  background-color: #bbbdc0;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

.backgrounditem {
  width: 4.5vh;
  min-width: 30px;
  height: 50%;
  border: 3px;
  border-style: outset;           
  border-color: #ececec #c0c0c0; 
  background: #bbbdc0;
  background-size: contain;
}

.backgrounditem:active {
  border: 3px;
  border-style: inset;           
  border-color: #ececec #c0c0c0;   
}
