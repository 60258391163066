.desktop {
  position: absolute;
  bottom: 0;
  right: 0;
  height: calc(90vh + 5px);
  max-height: calc(100vh - 65px);
  min-width: calc(100vw - 150px);
  width: calc(91.5vw + 3px);
  max-width: calc(100vw - 80px);
}

.innerdesktop {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 4px 4px 10px 0 rgba(0,0,0,0.50);
}

@media only screen 
  and (max-device-width: 750px)
  and (orientation: landscape) {
    .desktop {
      position: absolute;
      bottom: 0;
      right: 30px;
      width: calc(100% - 150px);
      max-width: 100vw;
      min-width: auto;
      max-height: 100vh;
      min-height: auto;
      height: 100%;
    }
}

@media only screen 
  and (max-device-width: 750px)
  and (orientation: portrait) {
    .desktop {
      position: absolute;
      bottom: 30px;
      right: 0;
      width: 100%;
      max-width: 100vw;
      min-width: auto;
      max-height: 100vh;
      min-height: auto;
      height: calc(100% - 156px);
    }
}