.row {
  width: 100%;
  border: 1px solid black;
  font-family: "FixedsysExcelsior301Regular";
  padding: 5px 5px 5px 5px;
  display: flex;
  flex-direction: row;
}

.row:hover {
  cursor: pointer;
}

.name {
  margin-left: 7px;
  color: yellow;
  overflow-x: hidden;
}
