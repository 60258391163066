.schedulepicker {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  background: yellow;
}

.schedulepickerday {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 33.33333%;
  text-align: center;
  line-height: 30px;
  background: black;
  font-size: 20px;
  font-family: "FixedsysExcelsior301Regular";
  color: red;
  border: 1px solid white;
}

@media only screen 
  and (max-device-width: 1124px) {
    .schedulepickerday {
      width: 100%;
    }
}