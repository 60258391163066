.holder {
  flex: 1;
  overflow: hidden;
  height: 100%;
}

.nowplaying {
  overflow: hidden;
  height: 100%;
  width: 100%;
  color: white;
  font-size: 8vh;
  font-family: 'Arial Narrow', Arial, sans-serif;
  line-height: 9vh;
  z-index: 2;
  position: relative;
}

@media only screen 
  and (max-device-width: 1124px) {
    .nowplaying {
      min-width: 150px;
    }
}

@media only screen 
  and (max-height: 600px) {
    .nowplaying {
      font-size: 40px;
      line-height: 60px;
    }
}