.sidebar {
  height: 91vh;
  background: black;
  min-width: 80px;
  width: calc(8.5vw + 3px);
  max-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;
  border-left: 4px ridge; 
  border-right: 4px ridge; 
  border-bottom: 4px ridge; 
  border-color: #ececec #d3d3d3; 
}

.icons {
  margin: 0;
  height: 70vh;
  flex-grow: 1;
}

.element {
  width: 100%;
  border: 3px;
  border-style: outset;           
  border-color: #ececec #c0c0c0; 
  background: #bbbdc0;
}

.imgwrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.imgwrapper img {
  max-width: 80%;
  max-height: 80%;
  display: block;
  margin: auto;
  padding-top:10%;
  padding-bottom:10%;  
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
}

.element:active {
  border: 3px;
  border-style: inset;           
  border-color: #a1a3a7 #93969b; 
  background: #aeb0b4;
}

.social {
  height: calc(3.75vh - 3px);
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  font-family: "FixedsysExcelsior301Regular";
  font-size: 1vw;
  color: white;
  line-height: 2.5vh;
}

.social a {
  text-decoration: none;
  color: white;
  width: 33.333%;
}

.fb {
  border: 3px;
  border-style: outset;           
  border-color: #4c70ba  #2d4373; 
  background: #3b5998;
  width: 100%;
}

.fb:active {
  border: 3px;
  border-style: inset;           
  border-color: #2d4373 #263961;
  background: #344e86; 
}

.tw {
  border: 3px;
  border-style: outset;           
  border-color: #6b6b6b  #343434; 
  background: #000000;
  width: 100%;
}

.tw:active {
  border: 3px;
  border-style: inset;           
  border-color: #343434 #838383;
  background: #000000; 
}

.mc {
  border: 3px;
  border-style: outset;           
  border-color: #a184f2 #4112c5;
  background: #4909f5;
  width: 100%;
}

.mc:active {
  border: 3px;
  border-style: inset;           
  border-color: #4112c5 #a184f2;
  background: #3706bc;
}

.infopanel {
  width: 100%;
  height: 100px;
  margin: 0;
}

.blank {
  flex-grow: 1;
  width: 100%;
  border: 3px;
  border-style: outset;           
  border-color: #ececec #c0c0c0; 
  background: #bbbdc0;
}


.social img {
  height: 100%;
}
  

@media only screen 
  and (max-device-width: 750px)
  and (orientation: landscape) {
    .sidebar {
      left: 60px;
      position: fixed;
      height: 100vh;
      max-width: 60px;
      min-width: 60px;
    }

    .icons {
      margin: 0;
      height: 100%;
    }
}

@media only screen 
  and (max-device-width: 750px)
  and (orientation: portrait) {
    .sidebar {
      min-width: 100vw;
      max-width: 100vw;
      height: 60px;
    }

    .icons {
      height: 100%;
      display: flex;
    }

    .element {
      height: 100%;
    }
}

@media only screen
  and (max-width: 1200px) {
      .infopanel {
        height: 125px;
      }
  }