.archiveplayer {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 999;
}

.progress {
  background: red;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

@media only screen 
  and (max-device-width: 750px) {
    .archiveplayer {
      left: -60px;
    }
}