.audioplayer {
  height: 100%;
  text-align: center;
  background: grey;
}

.playbutton {
  display: block;
  min-width: 60px;
  width: 9vh;
  height:100%;
  border: 3px;
  border-style: outset;           
  border-color: #ececec #c0c0c0; 
  background: #bbbdc0;
}

.playbutton:active {
  border: 3px;
  border-style: inset;           
  border-color: #ececec #c0c0c0; 
}