.infopanel {
  background: black;
  height: 100%;
  font-family: sans-serif;
  color: white;
  font-family:"FixedsysExcelsior301Regular" ;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date {
  font-size: 16px;
  font-family:"FixedsysExcelsior301Regular" ;
}

.summary {
  width: 100%;
  font-size: 1.25vw;
  display: none;
}

.time {
  font-family:"FixedsysExcelsior301Regular" ;
  font-size: 24px;
  color: red;
  text-align: center;
  width: 100%;
  padding-top: 5px;
}

.onlive {
  height:24px;
  background: rgba(254,224,50,0.2);
  font-family: 'Arial Narrow', Arial, sans-serif;
  color: white;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}
