.archivesandschedule {
  min-width: 250px;
  width: 20vw;
  background: #798686;
}

.next {
  display: flex;
  width: 100%;
  height: 50%;
  background: black;
}

.nextLabel {
  width: 70px;
  height: 100%;
  border: 2px solid #fee439;
  text-align: center;
  padding: 3px 5px;
  color: #fee439;
  font-family: "FixedsysExcelsior301Regular";
  font-size: 3vh;
  line-height: 3.5vh;
}

.nextPlaying {
  flex-grow: 1;
  height: 100%;
  border: 1px solid black;
  text-align: center;
  background: black;
  color: white;
  font-family: "FixedsysExcelsior301Regular";
  font-size: 10px;
  overflow: hidden;
}

.buttons {
  height: 50%;
  display: flex;
}

.scheduleButton {
  line-height: 3.5vh;
  height: 100%;
  width: 50%;
  border: 3px;
  border-style: outset;
  border-color: #ececec #c0c0c0;
  background: #bbbdc0;
  text-align: center;
  color: white;
  font-family: "FixedsysExcelsior301Regular";
  font-size: 3vh;
}

.scheduleButton:active {
  border: 3px;
  border-style: inset;
  border-color: #ececec #c0c0c0;
}

.archiveButton {
  line-height: 3.5vh;
  height: 100%;
  width: 50%;
  border: 3px;
  border-style: outset;
  border-color: #ececec #c0c0c0;
  background: #bbbdc0;
  text-align: center;
  color: white;
  font-family: "FixedsysExcelsior301Regular";
  font-size: 3vh;
}

.archiveButton:active {
  border: 3px;
  border-style: inset;
  border-color: #ececec #c0c0c0;
}

@media only screen
  and (max-device-width: 1124px) {
    .archivesandschedule {
      width: 110px;
      min-width: 110px;
    }
}

@media only screen
  and (max-height: 600px) {
    .nextPlaying {
      font-size: 20px;
      line-height: 25px;
    }

    .archiveButton {
      line-height: 30px;
      font-size: 20px;
    }

    .scheduleButton {
      line-height: 25px;
      font-size: 20px;
    }
}
