.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.labelrow {
  margin-bottom: 1em;
}

.p {
  color: black;
  font-weight: bold;
  padding-bottom: 0.3em;
}

.warning {
  color: red;
  margin-top: 1em;
}

.button {
  cursor: pointer;
}