.uploads-queue-body {
	padding: 1em;
}

.table {
	font-family: arial, sans-serif;
	border-collapse: collapse;
	width: 100%;
	font-size: 14px;

	margin: 2em;
}

td,
th {
	text-align: left;
	font-size: 14px;
	border: 1px solid blue;
	/* text-align: left; */
	padding: 8px;
}

tr:nth-child(even) {
	background-color: #dddddd;
}

.circle {
	height: 30px;
	width: 30px;
	border-radius: 20px;
}

.green {
	background-color: green;
}

.red {
	background-color: red;
}

.yellow {
	background-color: yellow;
}

.red-text {
	color: red;
}

.yellow-text {
	color: yellow;
}

.green-text {
	color: green;
}

button {
	cursor: pointer;
	background-color: blue;
	color: white;
	padding: 0.5em;
	margin-top: 1em;
	margin-bottom: 1em;
}
