.tickertext {
  font-size: 2vh;
  line-height: 4vh;
  font-family:'Arial Narrow', Arial, sans-serif;
}

.event {
  text-decoration: none;
  color: black;
}

.special {
  color: blue;
  font-weight: 800;
}

.event:before {
  content: "\00BB";
  margin-right: 16px;
  margin-left: 16px;
}

@media (max-width: 1500px) {
  .tickertext {
    font-size: 15px;
    line-height: 30px;
  }
}