.admineditor {
	padding: 30px;
	border-top: 5px dashed black;
	min-height: 600px;
	background: lightgoldenrodyellow;
}

.section {
	padding: 30px;
	border: 3px double black;
	margin-bottom: 30px;
}

.label {
	font-weight: 800;
}

.buttons {
	margin-top: 30px;
}

.button {
	margin: 5px;
}

.image {
	max-width: 100%;
}

.input {
	display: block;
	margin-top: 5px;
	min-width: 400px;
	font-family: monospace;
}

.input::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #a3c2c2;
}

.error {
	color: red;
	padding-top: 1em;
}

@media only screen and (max-device-width: 750px) {
	.admineditor {
		padding: 0px;
	}

	.section {
		padding: 5px;
		margin-bottom: 10px;
	}

	.input {
		min-width: 90vw;
	}
}
