.footer {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background: yellow;
  font-family: 'Arial Narrow', Arial, sans-serif;
  margin: 0;
  padding: 0;
  z-index: 9;
}

.backtolive {
  background: red;
}

#eventticker {
  width: 100%;
  overflow: hidden;
}

@media only screen
  and (max-device-width: 750px)
  and (orientation: landscape) {
    .footer {
      position: fixed;
      top: 100%;
      width: 100vh;
      height: 30px;
      left: calc(100vw - 30px);
      transform: rotate(270deg);
      transform-origin: 0% 00%;
    }

    .mobilehelper {
      position: fixed;
      top: 0;
      right: 0;
      width: 30px;
      height: 100vh;
      background: yellow;
    }
}

@media only screen
  and (max-device-width: 750px)
  and (orientation: portrait) {
    .footer {
      width: 100vw;
      height: 30px;
    }
}
