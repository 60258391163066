.wrapper {
  position: relative;
  left: 0;
  z-index: 999;
  width: 400px;
  background: black;
  left: 0px;
  box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.50);
}

@media only screen
  and (max-device-width: 750px)
  and (orientation: landscape) {
    .wrapper {
      max-height: 100vh;
      overflow-y: auto;
      width: 100%;
    }
}

@media only screen 
  and (max-device-width: 750px)
  and (orientation: portrait) {
    .wrapper {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
}
