.eastereggs {
  width: 4.5vh;
  min-width: 30px;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

.egg {
  width: 4.5vh;
  min-width: 30px;
  height: 50%;
  border: 3px;
  border-style: outset;
  border-color: #ececec #c0c0c0;
  background: #bbbdc0;
  background-size: contain;
}

.egg img {
  width: 100%;
  height: 100%;
}

.egg:active {
  border: 3px;
  border-style: inset;
  border-color: #ececec #c0c0c0;
}

.tooltip {
  border: 1px solid black;
  font-family: Arial !important;
  font-style: italic !important;
  font-size: 13px !important;
  pointer-events: auto !important;
  background: beige !important;
  padding: 3px !important;
  border-radius: 0px !important;
  color: black !important;
}

.tooltip:before {
  border-top-style: none !important;
  border-right-style: none !important;
  border-left-style: none !important;
  border-bottom-style: none !important;
}

.tooltip:after {
  border-top-style: none !important;
  border-right-style: none !important;
  border-left-style: none !important;
  border-bottom-style: none !important;
}
