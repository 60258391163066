.show {
  width: 100%;
  border: 1px solid #1c1c00;
  font-family: "FixedsysExcelsior301Regular";
  padding: 5px 5px 5px 5px;
  display: flex;
  flex-direction: row;
}

.time {
  margin-left: 7px;
  color: red;
  min-width: 75px;
}

.name {
  margin-left: 7px;
  color: white;
  width: calc(100% - 125px);
  overflow-x: hidden;
}

.rerun {
  margin-left: 10px;
  color: yellow;
  float: right;
}

.clear {
  clear: both;
}

.current {
  width: 100%;
  border: 1px solid black;
  font-family: "FixedsysExcelsior301Regular";
  padding: 5px 5px 5px 5px;
  display: flex;
  flex-direction: row;
  background: darkgoldenrod;
}
