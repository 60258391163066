.logoholder {
  z-index: 9;
  min-width: 76px;
  width: calc(8.5vw - 2px);
  max-width: 145px;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #fee032;
  border-right: 4px ridge;
  border-color: #ececec #d3d3d3;
}

.logo {
  height: 95%;
  margin: auto auto 5% auto;
}
