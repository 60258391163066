.horizontal {
  -webkit-appearance: none;
  width: 100%;
  margin: 5.5px 0;
}
.horizontal:focus {
  outline: none;
}
.horizontal::-webkit-slider-runnable-track {
  width: 100%;
  height: 0px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.18), 0px 0px 1px rgba(13, 13, 13, 0.18);
  background: #000000;
  border: 0.2px solid #0b0101;
}
.horizontal::-webkit-slider-thumb {
  box-shadow: 0.4px 0.4px 0.9px #000000, 0px 0px 0.4px #0d0d0d;
  border: 1.6px solid #000000;
  height: 11px;
  width: 7px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5.7px;
}
.horizontal:focus::-webkit-slider-runnable-track {
  background: #0d0d0d;
}
.horizontal::-moz-range-track {
  width: 100%;
  height: 0px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.18), 0px 0px 1px rgba(13, 13, 13, 0.18);
  background: #000000;
  border: 0.2px solid #0b0101;
}
.horizontal::-moz-range-thumb {
  box-shadow: 0.4px 0.4px 0.9px #000000, 0px 0px 0.4px #0d0d0d;
  border: 1.6px solid #000000;
  height: 11px;
  width: 7px;
  background: #ffffff;
  cursor: pointer;
}
.horizontal::-ms-track {
  width: 100%;
  height: 0px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.horizontal::-ms-fill-lower {
  background: #000000;
  border: 0.2px solid #0b0101;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.18), 0px 0px 1px rgba(13, 13, 13, 0.18);
}
.horizontal::-ms-fill-upper {
  background: #000000;
  border: 0.2px solid #0b0101;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.18), 0px 0px 1px rgba(13, 13, 13, 0.18);
}
.horizontal::-ms-thumb {
  box-shadow: 0.4px 0.4px 0.9px #000000, 0px 0px 0.4px #0d0d0d;
  border: 1.6px solid #000000;
  height: 11px;
  width: 7px;
  background: #ffffff;
  cursor: pointer;
  height: 0px;
}
.horizontal:focus::-ms-fill-lower {
  background: #000000;
}
.horizontal:focus::-ms-fill-upper {
  background: #0d0d0d;
}

.vertical {
  transform: rotate(270deg);
  width: 100px;
  top: 40%;
  left: -75%;
  position: absolute;
}